import { FC, useEffect, useState, useContext } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { Box, Text, Flex } from 'atoms';
import { persist } from 'utils/persist';
import { FormContext } from '../RegisterPage';

import useAuth from 'pages/Auth/context/Authentication';

type formValuesType = {
	school?: string;
	cohort?: any;
	code?: string;
};

export type SignupFormProps = {
	onSubmit?: (
		values?: formValuesType,
		formikHelpers?: FormikHelpers<formValuesType>
	) => void | Promise<void>;
	defaultInitialValues?: any;
	invitationCode?: any;
	userType?: string;
	schools?: any;
};

const ClientWelcome: FC<SignupFormProps> = ({ onSubmit, userType = 'coach', schools }) => {
	//const { loading } = useFormSubmitWithLoading(onSubmit);
	//const navigate = useNavigate();
	const { activeStepIndex, setActiveStepIndex, formData, setFormData }: any =
		useContext(FormContext);

	const [registrationComplete, setRegistrationComplete] = useState(false);
	//const [googleRegistrationComplete, setGoogleRegistrationComplete] = useState(false);

	const {
		state: { outsetaRef },
	} = useAuth();

	function getInitValue() {
		if (formData) {
			return {
				school: formData?.school || undefined,
				cohort: formData?.cohort || undefined,
				code: formData?.code || undefined,
			};
		} else {
			return {
				school: undefined,
				cohort: undefined,
				code: undefined,
			};
		}
	}

	const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

	const [initialValue, setInitialValue] = useState<any>(getInitValue());

	useEffect(() => {
		setInitialValue(getInitValue());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeStepIndex]);

	useEffect(() => {
		outsetaRef?.current?.on('signup', (data?: any) => {
			setRegistrationComplete(true);
			//navigate('/register/client');

			// if (data?.PersonAccount?.[0]?.Person?.OAuthGoogleProfileId) {
			// 	setGoogleRegistrationComplete(true);
			// 	setTimeout(() => {
			// 		const button = document.querySelector(
			// 			'.o--Button--btn.o--Button--borderWidth-1px.o--Button--buttonStyle-solid.o--Button--cornerShape-rounded.o--Button--displayMode-light'
			// 		) as HTMLElement;

			// 		if (button) {
			// 			console.log('Button found, clicking...');
			// 			button.click();
			// 		} else {
			// 			console.log('Button not found!');
			// 		}
			// 	}, 500);
			// }
		});
	}, []);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValue}
			onSubmit={async (formValues, actions) => {
				// Double click 2 time api trigger issue fix
				await delay(500);
				let data = {
					...formData,
					...formValues,
					completedStep: formData?.completedStep || 0,
				};
				setFormData(data);
				persist('details', data, 'localStorage');
				setActiveStepIndex(1);
			}}
		>
			{({ values, setFieldValue }) => (
				<Form>
					<Box mt="3rem">
						{!registrationComplete && (
							<>
								<Text
									fontSize="23px"
									color="primary.50"
									my="0.7rem"
									fontFamily="Archivo-Bold"
									mb="2rem"
								>
									Set up your account
								</Text>

								<Text fontSize="16px" mb="1.2rem">
									We just need a few more details to get your account setup.
								</Text>
							</>
						)}

						{/* {googleRegistrationComplete && (
							<Flex justifyContent="center">
								<Box justifyContent="center" display={'grid'}>
									<Spin
										indicator={
											<LoadingOutlined style={{ fontSize: 48 }} spin />
										}
									/>
									<Text fontSize="16px" mb="1.2rem" mt="3rem" fontWeight={600}>
										Do not refresh the page
									</Text>
								</Box>
							</Flex>
						)} */}

						<div id="client-signup-embed"></div>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
export default ClientWelcome;
